<template>
  <!-- personal:data START -->
  <div class="account-personal-data">
    <!-- form:personal:data START -->
    <el-form
      status-icon
      :rules="rules"
      ref="registerForm"
      :model="registerForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="submitForm('registerForm')"
    >
      <div class="is-half">
        <!-- form:personal:data:gender START -->
        <el-form-item prop="gender">
          <el-select
            v-if="user"
            v-model="registerForm.gender"
            :placeholder="`${$t('login.gender.select')} *`"
            @keyup.enter.native="submitForm('registerForm')"
          >
            <el-option
              v-for="item in gender"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select
            v-if="!user"
            class='f-like-text-loading-input-icon'
            :value="''"
            :placeholder="``"
          ></el-select>
        </el-form-item>
        <!-- form:personal:data:gender END -->

        <!-- form:personal:data:title START -->
        <el-form-item prop="titel">
          <el-input
            :placeholder="$t('login.title')"
            v-model="registerForm.title"
            @keyup.enter.native="submitForm('registerForm')"
            v-if="user"
          ></el-input>
          <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
        </el-form-item>
        <!-- form:personal:data:title END -->
      </div>

      <!-- form:personal:data:first-name START -->
      <el-form-item prop="firstName">
        <el-input
          :placeholder="`${$t('login.firstname')} *`"
          v-model="registerForm.firstName"
          @keyup.enter.native="submitForm('registerForm')"
          v-if="user"
        ></el-input>
        <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
      </el-form-item>
      <!-- form:personal:data:first-name END -->

      <!-- form:personal:data:last-name START -->
      <el-form-item prop="lastName">
        <el-input
          v-if="user"
          :placeholder="`${$t('login.lastname')} *`"
          v-model="registerForm.lastName"
          @keyup.enter.native="submitForm('registerForm')"
        ></el-input>
        <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
      </el-form-item>
      <!-- form:personal:data:last-name END -->

      <!-- form:personal:data:birth-date START -->
      <el-form-item prop="birthDate">
        <el-date-picker
          v-if="user"
          inputmode="none"
          v-model="registerForm.birthDate"
          :clearable=false
          type="date"
          format="dd.MM.yyyy"
          :placeholder="`${$t('login.birthdate')} *`"
          :pickerOptions="pickerOptions"
          @keyup.enter.native="submitForm('registerForm')"
        ></el-date-picker>
        <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
      </el-form-item>
      <!-- form:personal:data:birth-date END -->

      <!-- form:personal:data:road START -->
      <el-form-item prop="road">
        <el-input
          v-if="user"
          :placeholder="`${$t('login.road')} *`"
          v-model="registerForm.road"
          @keyup.enter.native="submitForm('registerForm')"
        ></el-input>
        <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
      </el-form-item>
      <!-- form:personal:data:road END -->

      <div class="is-half">
        <!-- form:personal:data:houseNumber START -->
        <el-form-item prop="houseNumber">
          <el-input
            v-if="user"
            :placeholder="`${$t('login.housenumber')} *`"
            v-model="registerForm.houseNumber"
            @keyup.enter.native="submitForm('registerForm')"
          ></el-input>
          <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
        </el-form-item>
        <!-- form:personal:data:houseNumber END -->

        <!-- form:personal:data:staircaseNumber START -->
        <el-form-item prop="staircaseNumber">
          <el-input
            v-if="user"
            :placeholder="`${$t('login.staircasenumber')} *`"
            v-model="registerForm.staircaseNumber"
            @keyup.enter.native="submitForm('registerForm')"
          ></el-input>
          <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
        </el-form-item>
        <!-- form:personal:data:staircaseNumber END -->
      </div>

      <div class="is-half">
        <!-- form:personal:data:floor START -->
        <el-form-item prop="floor">
          <el-input
            v-if="user"
            :placeholder="`${$t('login.floor')}`"
            v-model="registerForm.floor"
            @keyup.enter.native="submitForm('registerForm')"
          ></el-input>
          <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
        </el-form-item>
        <!-- form:personal:data:floor END -->

        <!-- form:personal:data:door START -->
        <el-form-item prop="door">
          <el-input
            v-if="user"
            :placeholder="`${$t('login.door')}`"
            v-model="registerForm.door"
            @keyup.enter.native="submitForm('registerForm')"
          ></el-input>
          <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
        </el-form-item>
        <!-- form:personal:data:door END -->
      </div>

      <!-- form:personal:data:postcode START -->
      <el-form-item prop="postcode">
        <el-input
          v-if="user"
          :placeholder="`${$t('login.postcode')} *`"
          v-model="registerForm.postcode"
          @keyup.enter.native="submitForm('registerForm')"
        ></el-input>
        <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
      </el-form-item>
      <!-- form:personal:data:postcode END -->

      <!-- form:personal:data:place START -->
      <el-form-item prop="place">
        <el-input
          v-if="user"
          :placeholder="`${$t('login.place')} *`"
          v-model="registerForm.place"
          @keyup.enter.native="submitForm('registerForm')"
        ></el-input>
        <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
      </el-form-item>
      <!-- form:personal:data:place END -->

      <!-- form:personal:data:country START -->
      <el-form-item prop="country">
        <el-select
          v-if="user && isCurentLangEn"
          v-model="registerForm.country"
          :placeholder="`${$t('login.country.select')} *`"
          @keyup.enter.native="submitForm('registerForm')"
        >
          <el-option
            v-for="item in country"
            :key="item.code"
            :label="item.name_en"
            :value="item.code">
          </el-option>
        </el-select>
        <el-select
          v-model="registerForm.country"
          :placeholder="`${$t('login.country.select')} *`"
          v-else
        >
          <el-option
            v-for="item in country"
            :key="item.code"
            :label="item.name_de"
            :value="item.code">
          </el-option>
        </el-select>
        <el-select
          v-if="!user"
          class='f-like-text-loading-input-icon'
          :value="''"
          :placeholder="``"
        ></el-select>
      </el-form-item>
      <!-- form:personal:data:country END -->

      <!-- company:switch START -->
      <el-form-item>
        <el-switch
          v-model="companySwitch"
          :inactive-text="$t('login.firma')"
          active-color="#353635"
          inactive-color="#A0A0A0"
        ></el-switch>
      </el-form-item>
      <!-- company:switch END -->

      <!-- form:personal:data:company START -->
      <el-form-item prop="company" v-if="companySwitch">
        <el-input
          v-if="user"
          :placeholder="`${$t('login.company')}`"
          v-model="registerForm.company"
          @keyup.enter.native="submitForm('registerForm')"
        ></el-input>
        <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
      </el-form-item>
      <!-- form:personal:data:company END -->

      <!-- form:personal:data:companyUID START -->
      <el-form-item prop="companyUID" v-if="companySwitch">
        <el-input
          v-if="user"
          :placeholder="`${$t('login.companyuid')}`"
          v-model="registerForm.companyUID"
          @keyup.enter.native="submitForm('registerForm')"
        ></el-input>
        <el-input v-if="!user" class='f-like-text-loading-input-full'></el-input>
      </el-form-item>
      <!-- form:personal:data:companyUID END -->

      <!-- form:personal:data:submit START -->
      <el-form-item>
        <el-button
          class="btn-default"
          type="primary"
          v-on:click="submitForm('registerForm')"
        >{{ $t('login.save') }}</el-button>

      </el-form-item>
      <!-- form:personal:data:submit END -->

    </el-form>
    <!-- form:personal:data END -->

    <!-- form:personal:data:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:personal:data:loading END -->
  </div>
  <!-- personal:data END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';
import validateMixin from '@/mixins/validateMixin';

export default {
  name: 'personal-data',
  mixins: [titleMixin, validateMixin],
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
  },
  data() {
    const t = this;
    /**
     * Custom Validation Functions
     */
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.personaldata.headline'),

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.update.headline'),
      loadingExcerpt: this.$t('loading.update.excerpt'),

      /**
       * Form (Variables)
       */
      user: null,
      companySwitch: false,
      serverErrorMessages: [],
      globalErrorMessage: false,
      globalSuccessMessage: '',
      country: null,
      isCurentLangEn: !!(localStorage && localStorage.getItem('lang') === 'en_US'),
      gender: [
        {
          value: 1,
          label: this.$t('login.gender.male'),
        }, {
          value: 2,
          label: this.$t('login.gender.female'),
        }, {
          value: 3,
          label: this.$t('login.gender.diverse'),
        },
      ],

      /**
       * Form (Models) + Pass data to mixin
       */
      registerForm: {
        gender: '',
        title: '',
        firstName: '',
        lastName: '',
        birthDate: '',
        road: '',
        houseNumber: '',
        staircaseNumber: '',
        floor: '',
        door: '',
        postcode: '',
        place: '',
        country: '',
        company: '',
        companyUID: '',
      },

      /**
       * Form (Rules - Validation)
       */
      rules: {
        gender: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        firstName: [
          {
            required: true,
            min: 2,
            max: 255,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        lastName: [
          {
            required: true,
            min: 2,
            max: 255,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        birthDate: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        road: [
          {
            required: true,
            min: 2,
            max: 255,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        houseNumber: [
          {
            required: true,
            min: 1,
            max: 10,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        floor: [
          {
            required: false,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        door: [
          {
            required: false,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        postcode: [
          {
            required: true,
            min: 4,
            max: 5,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        place: [
          {
            required: true,
            min: 2,
            max: 255,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        country: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
      pickerOptions: {
        disabledDate(date) {
          const age = t.$date(new Date()).diff(t.$date(date), 'year');
          return age < 12;
        },
      },
    };
  },
  async created() {
    this.country = await this.FETCH_COUNTRIES();
    this.user = await this.FETCH_USER();

    // populate user data
    if (this.user) {
      this.gender.forEach((g) => {
        if (g.value === this.user.genderID) {
          this.registerForm.gender = g.label;
        }
      });
      this.registerForm.title = this.user.academicTitle;
      this.registerForm.firstName = this.user.firstName;
      this.registerForm.lastName = this.user.lastName;
      this.registerForm.birthDate = this.$date(this.user.dateOfBirth);
      this.registerForm.road = this.user.street;
      this.registerForm.houseNumber = this.user.houseNumber;
      this.registerForm.staircaseNumber = this.user.staircaseNumber;
      this.registerForm.floor = this.user.floorNumber;
      this.registerForm.door = this.user.doorNumber;
      this.registerForm.postcode = this.user.zip;
      this.registerForm.place = this.user.city;
      this.country.forEach((c) => {
        if (c.code === this.user.countryCode) {
          if (localStorage.getItem('lang') === 'de_AT') {
            this.registerForm.country = c.name_de;
          } else if (localStorage.getItem('lang') === 'en_US') {
            this.registerForm.country = c.name_en;
          } else {
            this.registerForm.country = c.name_de;
          }
        }
      });
      this.registerForm.company = this.user.companyName;
      this.registerForm.companyUID = this.user.companyVatNumber;

      // switch on/off company data if fields are (not) filled
      if (this.registerForm.company || this.registerForm.companyUID) {
        this.companySwitch = true;
      } else {
        this.companySwitch = false;
      }
    }
  },
  methods: {
    async FETCH_USER() {
      await this.$store.dispatch('setUser');
      return this.$store.getters.user;
    },

    async FETCH_COUNTRIES() {
      await this.$store.dispatch('setCountries');
      return this.$store.getters.countries;
    },

    submitForm(formName) {
      const formData = new FormData();
      if (formName === 'registerForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVisible = true;
            /**
             * Locale
             */
            formData.append('locale', localStorage.getItem('lang'));
            /**
             * Personal Data
             */
            this.gender.forEach((g) => {
              if (g.label === this.registerForm.gender || g.value === this.registerForm.gender) {
                this.registerForm.gender = g.value;
                formData.append('genderID', this.registerForm.gender);
              }
            });
            formData.append('academicTitle', this.registerForm.title);
            formData.append('firstName', this.registerForm.firstName);
            formData.append('lastName', this.registerForm.lastName);
            formData.append('dateOfBirth', this.$date(this.registerForm.birthDate).format('YYYY-MM-DD'));

            formData.append('street', this.registerForm.road);
            formData.append('houseNumber', this.registerForm.houseNumber);
            formData.append('staircaseNumber', this.registerForm.staircaseNumber);
            formData.append('floorNumber', this.registerForm.floor);
            formData.append('doorNumber', this.registerForm.door);
            formData.append('zip', this.registerForm.postcode);
            formData.append('city', this.registerForm.place);
            this.country.forEach((c) => {
              if (localStorage.getItem('lang') === 'de_AT') {
                if (c.name_de === this.registerForm.country
                  || c.code === this.registerForm.country) {
                  this.registerForm.country = c.code;
                  formData.append('countryCode', this.registerForm.country);
                }
              } else if (localStorage.getItem('lang') === 'en_US') {
                if (c.name_en === this.registerForm.country
                  || c.code === this.registerForm.country) {
                  this.registerForm.country = c.code;
                  formData.append('countryCode', this.registerForm.country);
                }
              } else {
                this.registerForm.country = c.code;
              }
            });

            formData.append('companyName', this.registerForm.company);
            formData.append('companyVatNumber', this.registerForm.companyUID);

            // Display the key/value pairs
            /* [...formData.entries()].forEach((row) => {
              console.log(`${row}`);
            }); */

            // Update user data
            this.$http.post(`${process.env.VUE_APP_BASE_URL}user`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                Authorization: `Bearer ${localStorage.getItem('auth')}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  const user = response.data;

                  // mutating to store for client rendering &
                  // saving user data in cookie for server rendering
                  this.updateItem('user', JSON.stringify(user));

                  // Close loading
                  this.loadingVisible = false;
                  this.globalSuccessMessage = response.request.statusText;
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });

                  // switch on/off company data if fields are (not) filled
                  if (this.registerForm.company || this.registerForm.companyUID) {
                    this.companySwitch = true;
                  } else {
                    this.companySwitch = false;
                  }

                  // notification - success
                  this.success();
                } else {
                  this.globalErrorMessage = true;
                  this.resetForm.agbs = false;

                  console.log('error register submit!!');
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  // Close loading
                  this.loadingVisible = false;
                  this.errorStatus = error.response.data;
                  this.serverErrorMessages = this.errorStatus.errors;

                  this.serverErrorMessages.forEach((message) => {
                    this.error(`${this.$t(`error.${message.code}`)}`);
                  });
                }
              });
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
      }
    },

    updateItem(item, value) {
      const prevData = JSON.parse(localStorage.getItem(item));
      Object.keys(value).forEach((val) => {
        prevData[val] = value[val];
      });
      localStorage.setItem(item, JSON.stringify(prevData));
    },

    success() {
      this.$notify({
        message: this.$t('notification.success'),
        type: 'success',
        position: 'bottom-right',
      });
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },
  },
};
</script>
